<template>
  <vf-utility-navigation
    v-bind="forwarded"
    :class="{
      'f-col': $feature.tempHeaderVariation === 'elevated',
      '!text-sm': $feature.tempHeaderVariation === 'basic',
    }"
  >
    <template #loyalty v-if="$feature.tempHeaderVariation !== 'default'">
      <span />
    </template>
  </vf-utility-navigation>
</template>

<script lang="ts" setup>
import { useForwardPropsEmits } from 'radix-vue'
import VfUtilityNavigation, { type Emits, type Props } from '#brand/components/vf/utility-navigation/UtilityNavigation.vue'

const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const forwarded = useForwardPropsEmits(props, emits)
</script>
